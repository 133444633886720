import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,858.000000) scale(0.100000,-0.100000)">
          <path d="M5700 6905 c-92 -29 -201 -115 -288 -228 -66 -85 -97 -141 -128 -233
-60 -180 -15 -314 114 -338 28 -5 33 -3 30 11 -2 10 -19 28 -38 41 -48 32 -59
57 -60 133 0 75 21 145 78 259 50 102 192 247 284 290 67 32 102 37 141 19
117 -53 46 -349 -130 -539 -36 -38 -40 -57 -19 -85 13 -18 15 -18 41 11 79 84
130 154 169 237 99 205 85 362 -38 418 -48 22 -94 23 -156 4z"/>
<path d="M5203 6372 c-8 -14 -32 -21 -95 -29 -129 -17 -472 -71 -588 -93 -58
-11 -140 -24 -182 -30 -104 -13 -166 -28 -197 -46 -15 -9 -56 -59 -91 -111
-36 -53 -105 -156 -155 -229 -49 -73 -147 -217 -217 -321 -70 -103 -168 -246
-217 -318 -266 -384 -331 -488 -331 -527 0 -77 50 -142 147 -190 66 -32 53
-39 131 69 56 78 175 193 287 277 99 74 322 189 449 231 120 39 336 82 481 95
330 30 774 -36 1070 -160 22 -9 60 -24 85 -34 25 -10 60 -24 78 -32 18 -8 37
-12 42 -9 5 3 35 49 66 103 31 53 74 125 95 160 64 103 69 85 -172 566 -246
491 -269 533 -306 568 -34 32 -102 63 -160 73 -42 7 -43 6 -43 -23 0 -16 -5
-42 -10 -59 -13 -36 10 -98 40 -108 28 -9 70 -67 70 -96 -1 -71 -100 -103
-181 -57 -46 25 -75 78 -60 107 8 14 7 29 -5 57 -11 25 -14 60 -12 110 2 41 1
74 -2 74 -3 0 -10 -8 -17 -18z m-739 -191 c17 -6 14 -10 -18 -29 -21 -12 -41
-20 -46 -17 -5 3 -17 -6 -28 -20 -11 -14 -27 -25 -35 -25 -26 0 -70 -22 -115
-58 -28 -23 -47 -31 -53 -25 -7 7 -21 0 -41 -18 -16 -16 -34 -29 -39 -29 -13
0 -11 28 2 32 6 2 21 28 34 58 33 81 74 105 200 120 55 7 102 14 104 16 6 5
12 4 35 -5z"/>
<path d="M4620 4910 c-147 -21 -197 -32 -305 -66 -230 -74 -444 -205 -581
-356 -51 -56 -124 -153 -122 -160 2 -5 49 -31 106 -58 l103 -49 18 32 c40 70
149 185 228 242 46 33 89 65 96 71 33 28 212 101 352 143 99 30 249 42 433 35
103 -4 202 -7 220 -8 17 -1 32 -4 32 -8 0 -5 9 -8 20 -8 10 0 45 -7 77 -16 32
-9 74 -19 93 -23 48 -11 228 -79 267 -101 l31 -18 26 39 c14 21 26 42 26 47 0
5 9 19 20 32 23 27 20 35 -23 54 -199 90 -381 140 -642 176 -169 23 -308 23
-475 0z m-170 -70 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m810 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m-940 -50 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m947 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m136 -61 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-1036
-29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5235 4470 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M5308 4453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5494 4385 c11 -8 27 -15 35 -15 10 1 7 6 -9 15 -33 19 -50 18 -26 0z"/>
<path d="M5555 4360 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6116 3070 c-66 -21 -103 -89 -85 -155 12 -41 44 -68 107 -90 85 -30
112 -44 112 -60 0 -19 -46 -30 -79 -19 -14 5 -30 19 -36 32 -8 19 -18 22 -62
22 l-53 0 0 -75 0 -75 50 0 c44 0 50 3 50 21 0 11 3 19 8 17 80 -42 107 -50
145 -44 63 10 104 34 121 71 41 85 7 132 -131 185 -85 32 -107 55 -77 77 10 7
24 13 30 13 28 0 65 -25 70 -47 6 -20 12 -23 60 -23 l54 0 0 75 0 75 -49 0
c-41 0 -50 -3 -54 -21 l-6 -21 -28 21 c-32 24 -105 34 -147 21z"/>
<path d="M2150 3031 c0 -22 4 -41 10 -43 5 -2 -8 -59 -29 -128 -32 -105 -42
-126 -60 -128 -17 -3 -21 -10 -21 -43 l0 -39 95 0 95 0 0 40 c0 36 -3 40 -24
40 -19 0 -23 4 -19 20 5 17 14 20 68 20 48 0 64 -4 69 -16 9 -23 8 -24 -19
-24 -22 0 -25 -4 -25 -40 l0 -40 115 0 115 0 0 40 c0 33 -3 40 -19 40 -19 0
-46 72 -112 298 l-12 42 -114 0 -113 0 0 -39z m138 -127 c17 -60 16 -64 -23
-64 -19 0 -35 3 -35 6 0 23 33 114 39 107 4 -4 13 -27 19 -49z"/>
<path d="M2550 3030 c0 -29 4 -40 15 -40 13 0 15 -21 15 -130 0 -109 -2 -130
-15 -130 -11 0 -15 -11 -15 -40 l0 -40 95 0 95 0 0 40 c0 33 -3 40 -20 40 -17
0 -20 7 -20 46 0 44 1 45 28 42 25 -3 28 -8 33 -56 9 -67 30 -98 75 -111 74
-21 119 8 126 79 4 41 -25 57 -35 18 -8 -29 -20 -16 -36 39 -8 29 -20 47 -38
57 l-27 14 31 15 c35 19 43 34 43 86 0 84 -59 111 -245 111 l-105 0 0 -40z
m224 -56 c34 -34 8 -84 -45 -84 -28 0 -29 1 -29 50 0 49 1 50 29 50 16 0 37
-7 45 -16z"/>
<path d="M3080 3031 c0 -22 4 -41 10 -43 5 -2 -7 -57 -28 -128 -33 -106 -42
-125 -60 -128 -18 -3 -22 -10 -22 -43 l0 -39 95 0 95 0 0 40 c0 36 -3 40 -24
40 -19 0 -23 4 -19 20 5 17 14 20 68 20 48 0 64 -4 69 -16 9 -23 8 -24 -19
-24 -22 0 -25 -4 -25 -40 l0 -40 115 0 115 0 0 40 c0 33 -3 40 -20 40 -19 0
-45 71 -111 298 l-12 42 -114 0 -113 0 0 -39z m140 -130 l18 -61 -39 0 c-21 0
-39 2 -39 5 0 15 34 124 37 121 2 -2 13 -31 23 -65z"/>
<path d="M3480 3030 c0 -29 4 -40 15 -40 13 0 15 -21 15 -130 0 -109 -2 -130
-15 -130 -11 0 -15 -11 -15 -40 l0 -40 133 0 c72 0 149 5 169 10 52 15 78 52
78 109 -1 49 -12 71 -47 89 l-22 12 25 25 c18 18 24 35 24 70 0 84 -48 105
-237 105 l-123 0 0 -40z m219 -54 c7 -8 11 -27 9 -43 -3 -24 -8 -28 -40 -31
l-38 -3 0 45 c0 44 1 46 29 46 16 0 34 -6 40 -14z m19 -158 c7 -7 12 -24 12
-38 0 -36 -17 -50 -62 -50 l-38 0 0 50 0 50 38 0 c21 0 43 -5 50 -12z"/>
<path d="M4040 3030 c0 -29 4 -40 15 -40 13 0 15 -21 15 -130 0 -109 -2 -130
-15 -130 -11 0 -15 -11 -15 -41 l0 -41 128 4 c133 4 182 19 224 65 50 55 64
173 28 243 -40 79 -120 109 -282 110 l-98 0 0 -40z m222 -51 c45 -24 61 -117
32 -189 -18 -44 -39 -60 -78 -60 l-26 0 0 130 0 130 26 0 c14 0 34 -5 46 -11z"/>
<path d="M4560 3031 c0 -22 4 -41 10 -43 5 -2 -7 -57 -28 -128 -32 -108 -41
-125 -60 -128 -18 -3 -22 -10 -22 -43 l0 -39 95 0 95 0 0 40 c0 36 -3 40 -24
40 -19 0 -23 4 -19 20 5 17 14 20 68 20 48 0 64 -4 69 -16 9 -23 8 -24 -19
-24 -22 0 -25 -4 -25 -40 l0 -40 115 0 115 0 0 40 c0 33 -3 40 -19 40 -17 0
-27 24 -66 148 -67 216 -46 192 -175 192 l-110 0 0 -39z m143 -138 l15 -53
-39 0 c-21 0 -39 3 -39 6 0 24 34 114 40 107 4 -4 14 -32 23 -60z"/>
<path d="M4950 2995 c0 -77 0 -77 45 -77 36 0 46 3 44 15 -5 42 2 57 26 57
l25 0 0 -130 0 -130 -25 0 c-22 0 -25 -4 -25 -40 l0 -40 110 0 110 0 0 40 c0
36 -2 40 -25 40 l-25 0 0 130 0 130 30 0 c28 0 30 -2 30 -39 0 -38 0 -39 38
-37 l37 1 3 78 3 77 -201 0 -200 0 0 -75z"/>
<path d="M5470 3031 c0 -22 4 -41 10 -43 5 -2 -7 -57 -28 -128 -33 -106 -42
-125 -60 -128 -18 -3 -22 -10 -22 -43 l0 -39 95 0 95 0 0 40 c0 36 -3 40 -24
40 -19 0 -23 4 -19 20 5 17 14 20 68 20 48 0 64 -4 69 -16 9 -23 8 -24 -19
-24 -22 0 -25 -4 -25 -40 l0 -40 115 0 115 0 0 40 c0 33 -3 40 -20 40 -16 0
-26 22 -60 133 -22 72 -46 148 -53 167 l-13 35 -112 3 -112 3 0 -40z m140
-130 l18 -61 -39 0 c-21 0 -39 2 -39 5 0 15 34 124 37 121 2 -2 13 -31 23 -65z"/>
<path d="M6440 3030 c0 -27 4 -40 14 -40 10 0 14 -26 18 -107 6 -120 21 -168
63 -201 59 -46 172 -53 225 -13 51 37 64 75 68 203 4 91 8 118 18 118 10 0 14
13 14 40 l0 40 -85 0 -85 0 0 -40 c0 -30 4 -40 16 -40 14 0 15 -14 12 -106 -3
-97 -5 -107 -27 -125 -26 -21 -58 -18 -78 9 -9 10 -13 52 -13 119 0 85 3 103
15 103 11 0 15 11 15 40 l0 40 -95 0 -95 0 0 -40z"/>
<path d="M6890 3030 c0 -29 4 -40 15 -40 13 0 15 -21 15 -130 0 -109 -2 -130
-15 -130 -11 0 -15 -11 -15 -40 l0 -40 133 0 c151 0 198 10 227 47 31 39 28
112 -6 147 -15 14 -30 26 -35 26 -4 0 3 11 16 25 19 18 25 35 25 70 0 84 -48
105 -237 105 l-123 0 0 -40z m218 -52 c17 -17 15 -65 -4 -72 -9 -3 -27 -6 -40
-6 -22 0 -24 4 -24 45 0 43 1 45 28 45 15 0 33 -5 40 -12z m20 -160 c7 -7 12
-24 12 -38 0 -36 -17 -50 -62 -50 l-38 0 0 50 0 50 38 0 c21 0 43 -5 50 -12z"/>
<path d="M2480 2180 c0 -118 2 -140 15 -140 13 0 15 22 15 140 0 118 -2 140
-15 140 -13 0 -15 -22 -15 -140z"/>
<path d="M3079 2308 c-1 -7 0 -65 0 -128 2 -134 6 -140 87 -140 67 0 84 20 84
102 0 80 -14 92 -102 88 l-38 -2 0 46 c0 34 -4 46 -15 46 -8 0 -15 -6 -16 -12z
m126 -173 l0 -70 -45 0 -45 0 -3 59 c-4 81 0 88 50 84 l43 -3 0 -70z"/>
<path d="M3534 2307 c-2 -7 -3 -69 -2 -137 3 -110 5 -125 21 -128 15 -3 17 9
17 137 0 120 -2 141 -15 141 -9 0 -18 -6 -21 -13z"/>
<path d="M6030 2180 c0 -118 2 -140 15 -140 13 0 15 22 15 140 0 118 -2 140
-15 140 -13 0 -15 -22 -15 -140z"/>
<path d="M6190 2180 c0 -129 1 -141 18 -138 15 3 17 18 17 138 0 120 -2 135
-17 138 -17 3 -18 -9 -18 -138z"/>
<path d="M1899 2190 c-1 -60 -2 -118 -3 -127 0 -10 7 -19 17 -21 14 -3 17 5
17 52 0 49 2 56 20 56 13 0 32 -19 56 -55 23 -34 44 -55 55 -55 25 0 24 4 -11
55 -35 51 -37 61 -10 68 26 7 40 45 31 87 -8 40 -26 47 -113 49 l-58 1 -1
-110z m136 59 c4 -11 4 -31 1 -44 -5 -18 -14 -24 -45 -27 -56 -5 -61 -1 -61
48 l0 44 49 0 c41 0 51 -3 56 -21z"/>
<path d="M2640 2285 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>
<path d="M3370 2285 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>
<path d="M3700 2285 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M4516 2285 c-19 -19 -20 -44 -5 -78 9 -21 7 -28 -15 -50 -31 -31 -33
-70 -6 -97 17 -17 32 -20 110 -18 83 2 111 11 75 24 -11 5 -13 16 -8 50 5 37
3 44 -11 44 -9 0 -16 -8 -16 -20 0 -11 -4 -20 -9 -20 -16 0 -101 110 -101 131
0 19 33 33 63 27 9 -2 17 2 19 7 5 18 -78 18 -96 0z m68 -167 c19 -24 31 -47
27 -51 -5 -5 -28 -7 -52 -5 -42 3 -44 5 -47 36 -3 31 11 62 28 62 5 0 24 -19
44 -42z"/>
<path d="M4960 2170 l0 -130 86 0 c64 0 85 3 82 13 -3 7 -29 13 -71 15 l-67 3
0 44 0 45 61 0 c81 0 77 24 -4 28 l-57 3 0 39 0 40 65 0 c51 0 65 3 65 15 0
12 -16 15 -80 15 l-80 0 0 -130z"/>
<path d="M3878 2263 c-2 -19 -10 -29 -28 -34 l-25 -6 26 -11 c24 -10 26 -15
28 -78 1 -37 7 -74 13 -81 12 -15 88 -18 88 -4 0 6 -15 11 -32 13 l-33 3 -3
73 -3 72 36 0 c44 0 46 18 3 22 -28 2 -34 8 -36 31 -4 35 -30 35 -34 0z"/>
<path d="M2833 2232 c-42 -6 -23 -21 30 -24 48 -3 52 -5 52 -28 0 -23 -5 -25
-53 -30 -55 -6 -72 -19 -72 -57 0 -39 26 -53 96 -52 l65 2 -3 91 -3 91 -45 5
c-25 3 -55 4 -67 2z m85 -134 c-3 -31 -5 -33 -43 -33 -30 0 -41 4 -43 18 -7
35 6 47 48 47 40 0 41 -1 38 -32z"/>
<path d="M6960 2232 c-14 -2 -31 -9 -37 -14 -17 -14 -17 -150 -1 -166 14 -14
128 -16 128 -2 0 6 -20 10 -45 10 -52 0 -65 16 -65 79 0 64 6 71 61 71 27 0
49 4 49 9 0 10 -53 18 -90 13z"/>
<path d="M2209 2213 c-9 -10 -14 -41 -14 -80 0 -53 4 -67 20 -79 23 -17 126
-15 132 2 3 8 -10 10 -41 7 -52 -6 -76 7 -76 43 0 21 5 23 63 26 l62 3 -2 33
c-2 49 -18 62 -78 62 -37 0 -57 -5 -66 -17z m109 -35 c3 -27 2 -28 -42 -28
-42 0 -46 2 -46 23 0 32 9 38 50 35 30 -3 35 -7 38 -30z"/>
<path d="M2640 2135 c0 -86 2 -96 18 -93 15 3 17 15 17 93 0 78 -2 90 -17 93
-16 3 -18 -7 -18 -93z"/>
<path d="M3370 2135 c0 -86 2 -96 18 -93 15 3 17 15 17 93 0 77 -2 90 -17 93
-16 3 -18 -6 -18 -93z"/>
<path d="M3700 2135 c0 -78 3 -95 15 -95 12 0 15 17 15 95 0 78 -3 95 -15 95
-12 0 -15 -17 -15 -95z"/>
<path d="M4055 2213 c61 -152 67 -174 56 -205 -6 -17 -11 -35 -11 -39 0 -16
28 -9 35 9 13 31 85 242 85 247 0 3 -6 5 -14 5 -9 0 -23 -26 -37 -70 -13 -38
-26 -70 -29 -70 -3 0 -16 32 -29 70 -18 57 -26 70 -43 70 -15 0 -18 -4 -13
-17z"/>
<path d="M5261 2184 l31 -46 -31 -43 c-35 -49 -37 -55 -17 -55 7 0 24 16 37
35 13 19 27 35 30 35 4 0 17 -16 30 -35 13 -19 30 -35 37 -35 18 0 15 12 -13
50 -14 19 -25 40 -25 48 0 9 11 30 25 48 29 38 30 44 10 44 -8 0 -24 -13 -37
-30 -12 -16 -25 -30 -29 -30 -4 0 -16 14 -26 30 -9 17 -26 30 -36 30 -15 0
-14 -6 14 -46z"/>
<path d="M5512 2218 c-7 -7 -12 -41 -12 -83 0 -42 5 -76 12 -83 14 -14 128
-16 128 -2 0 6 -19 10 -42 10 -64 0 -69 7 -66 81 l3 64 53 3 c30 2 50 7 47 13
-9 13 -109 11 -123 -3z"/>
<path d="M5756 2208 c-10 -14 -16 -44 -16 -75 0 -74 22 -95 96 -91 30 2 53 7
52 13 -2 6 -20 8 -41 7 -44 -4 -67 11 -67 44 0 21 5 23 63 26 l62 3 -3 34 c-4
51 -17 61 -78 61 -43 0 -56 -4 -68 -22z m112 -30 c3 -27 2 -28 -42 -28 -42 0
-46 2 -46 23 0 32 9 38 50 35 30 -3 35 -7 38 -30z"/>
<path d="M6356 2214 c-11 -11 -16 -35 -16 -79 0 -57 3 -66 24 -80 27 -18 135
-18 126 0 -3 6 -23 9 -46 7 -45 -4 -77 18 -72 47 2 12 17 17 66 19 l62 3 0 37
c0 21 -5 43 -12 50 -18 18 -113 15 -132 -4z m109 -34 c0 -22 -5 -25 -39 -28
-45 -4 -61 8 -51 38 6 18 13 21 48 18 37 -3 42 -6 42 -28z"/>
<path d="M6624 2217 c-3 -8 -4 -49 -2 -93 2 -65 6 -79 20 -82 15 -3 18 8 20
80 l3 83 40 0 40 0 5 -80 c4 -66 8 -80 23 -83 15 -3 17 6 17 73 0 108 -5 115
-91 115 -49 0 -71 -4 -75 -13z"/>
<path d="M7170 2215 c-15 -18 -24 -86 -16 -119 11 -43 32 -56 97 -56 44 0 59
4 59 14 0 11 -10 13 -39 9 -48 -7 -81 10 -81 42 0 23 4 24 63 27 l62 3 -2 33
c0 18 -7 39 -13 47 -17 20 -113 20 -130 0z m115 -25 c10 -31 -2 -40 -51 -40
-39 0 -44 2 -44 23 0 32 7 37 51 37 29 0 39 -5 44 -20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
